import React, { useEffect } from 'react';
import './term_privacy.css';

function Terms() {

    // useEffect(()=>{
    //     window.scrollTo(0, 0);
    // })

    return (
        <div className="terms">
            <div className="terms__bgbox1 "/>
             <div className="terms__bgbox2 "/>
            <div className="terms_wrapper">
                <div className="terms_title">
                    <h2>Terms of Service</h2>
                </div>
                <div className="terms_content">
                  <h4 className="terms_content_boldText">Overview</h4> 
                  <p className="terms_content_p">This Terms of Use Agreement (the "Agreement") sets forth the terms and conditions that apply to your use of the &nbsp;
                       <span className="terms_content_span">ReChat</span> website.
                       By completing the sign-up process, you indicate your agreement to be bound by all the terms of this Agreement.</p>

                   <h4 className="terms_content_boldText">Eligibility</h4> 
                    <p className="terms_content_p">
                    You must be at least 18 years old to visit or use this website in any manner. By accessing ReChat or accepting these Terms of Use, 
                    you represent and warrant to ReChat that you are at least 18 years old and have the right, authority, and capacity to agree to and 
                    comply with these Terms of Use. Additionally, you represent and warrant that your use of this website will comply with all applicable 
                    laws and regulations.
                    </p>
                    <h4 className="terms_content_boldText">Privacy of Information</h4> 
                    <p className="terms_content_p">"Personal Information" refers to any information about an identifiable individual.</p>
                    <p className="terms_content_p">ReChat collects personal information from you to create your online profile. ReChat 
                      will not use or disclose your personal information for purposes other than those outlined in this policy.</p>
                    <p className="terms_content_p">If you have any questions regarding our policies on handling personal information, 
                      or if you wish to request access to or correction of your personal information under our care and control, 
                      please contact us at: XXX.</p>

                    <h4 className="terms_content_boldText">By using ReChat you acknowledge that:</h4>
                    <ul>
                        <li className="terms_content_li">We cannot guarantee the security or privacy of information you provide via the Internet or 
                          through email, and you release us from any liability arising from the use of such information by third parties. </li>

                         <li className="terms_content_li">We cannot control, nor are we responsible for, the use of any information you provide to 
                          others. You should exercise caution when sharing personal information with others through ReChat. </li>

                         <li className="terms_content_li">We are not responsible for the content of messages sent by other ReChat 
                          users, and you release us from all liability related to any communications you may receive from them.</li>

                         <li className="terms_content_li">We are not responsible for the accuracy or content of any user profile on ReChat.</li>    

                         <li className="terms_content_li">We cannot guarantee or take responsibility for verifying the accuracy of information provided by other ReChat users.</li> 
                    </ul> 

                    <h4 className="terms_content_boldText">User Agreement</h4>
                    <p className="terms_content_p">This Agreement outlines your terms of use with ReChat. By using ReChat, you agree to comply with all the terms and conditions in this Agreement to become or remain a user.</p>

                    <h4 className="terms_content_boldText">Right to Use</h4>
<p className="terms_content_p">Your use of ReChat is subject to limitations, conditions and restrictions that we may establish and modify at our discretion. We reserve the right to alter, suspend or discontinue any aspect of ReChat at any time, including features, databases or content. We may impose limits on features or restrict your access to parts or all of ReChat without notice or liability.</p>

<h4 className="terms_content_boldText">Unlawful Use is Prohibited</h4>
<p className="terms_content_p">ReChat must not be used for any unlawful purpose. We reserve the right to deny usernames that impersonate others, violate trademarks or proprietary law, or that we determine to be vulgar or offensive in our sole discretion.</p>

<h4 className="terms_content_boldText">Code of Conduct</h4>
<p className="terms_content_p">Your use of ReChat must comply with the following Code of Conduct:</p>
<ul>
<li className="terms_content_li">Maintain the confidentiality of information shared by other users through ReChat. Do not share such information without explicit permission from the person who provided it.</li>
<li className="terms_content_li">Do not engage in harassment or offensive behavior, including posting private communications, pictures or recordings containing libelous, slanderous, abusive or defamatory statements, or racist, pornographic, obscene, or offensive language.</li>
<li className="terms_content_li">Respect all privacy rights, property rights, and other rights of every person.</li>
<li className="terms_content_li">Do not submit fraudulent or unlawful material or information that violates any law.</li>
<li className="terms_content_li">Do not use ReChat to distribute or promote material containing solicitations for funds, advertising, or solicitation for goods or services.</li>
<li className="terms_content_li">Do not distribute malicious code or engage in any activity that might harm ReChat or other members' systems.</li>
<li className="terms_content_li">Do not post or transmit contact information including email addresses, instant messenger nicknames, telephone numbers, postal addresses, URLs, or full names in your public posts.</li>
</ul>
<p className="terms_content_p">While we may permanently close accounts that violate this Code of Conduct, we cannot guarantee complete or consistent enforcement.</p>

<h4 className="terms_content_boldText">Usage Restrictions</h4>
<p className="terms_content_p">The following restrictions govern ReChat usage:</p>
<ul>
<li className="terms_content_li">You must be at least 18 years old to create an account or access ReChat.</li>
<li className="terms_content_li">You must create your account using your true identity and information, not on behalf of another person.</li>
<li className="terms_content_li">You may not create an account or use ReChat if you are a convicted sex offender.</li>
<li className="terms_content_li">You may not create an account if ReChat staff or moderators have previously removed you.</li>
<li className="terms_content_li">Your account is for personal use only, not commercial purposes.</li>
<li className="terms_content_li">Do not use your account to distribute commercial advertisements, chain letters, spam, or repetitive messages.</li>
<li className="terms_content_li">Do not engage in illegal conduct through your account.</li>
<li className="terms_content_li">Do not transfer your Account or Virtual Items (Badges, Credits, etc) to others without ReChat's explicit permission.</li>
<li className="terms_content_li">Do not use accounts or Virtual Items transferred from other users.</li>
<li className="terms_content_li">Only the original account creator may access the account.</li>
<li className="terms_content_li">You are responsible for any unauthorized use of your account by third parties.</li>
</ul>

<h4 className="terms_content_boldText">Personal Liability</h4>
<p className="terms_content_p">You assume full responsibility for all content you submit to ReChat. We do not accept responsibility or liability for any content published by users on ReChat. Each user bears sole responsibility for their published content.</p>

<h4 className="terms_content_boldText">Monitoring of Information</h4>
<p className="terms_content_p">We reserve the right to monitor all content and communications submitted to ReChat to ensure compliance with our content guidelines, code of conduct, and usage restrictions.</p>

<h4 className="terms_content_boldText">Removal of Information</h4>
<p className="terms_content_p">While we do not review every user submission, we reserve the right to delete, move, or edit any content that violates our Code of Conduct or content guidelines, or that we deem unacceptable. You remain responsible for all content you upload to ReChat or share with other users.</p>

<h4 className="terms_content_boldText">Termination of Access</h4>
<p className="terms_content_p">We may terminate or suspend your ReChat access at any time, with or without notice, for any reason including breach of this Agreement. This includes, but is not limited to, fraudulent, abusive, or illegal activity that affects others' enjoyment of ReChat. We may refer violations to law enforcement agencies.</p>

<h4 className="terms_content_boldText">Proprietary Information</h4>
<p className="terms_content_p">All information on ReChat is proprietary to us, our partners, or our users and protected by copyright. This applies whether or not content is marked as proprietary. You must obtain express written permission from the owner before modifying, copying, or distributing any information.</p>

<h4 className="terms_content_boldText">Suspension of Service</h4>
<p className="terms_content_p">ReChat bears no responsibility for service interruptions or suspensions, regardless of cause.</p>

<h4 className="terms_content_boldText">Ownership of Content and Virtual Items</h4>
<p className="terms_content_p">ReChat owns or has licensed rights to all content appearing on the service. You have no ownership rights to any content, Virtual Items (Badges, Credits, etc), or other account attributes on ReChat.</p>

<h4 className="terms_content_boldText">Account Security</h4>
<p className="terms_content_p">Your account is for your personal use only. You are responsible for all activity that occurs through your account, including any unauthorized use of your password.</p>

<h4 className="terms_content_boldText">DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h4>
<p className="terms_content_p">YOU USE RECHAT AND ITS RELATED SERVICES AT YOUR OWN RISK. RECHAT'S SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, EXCEPT THOSE WARRANTIES THAT CANNOT BE EXCLUDED UNDER LAW.</p>

<p className="terms_content_p">YOU ACKNOWLEDGE AND ACCEPT ALL RISKS OF USING THE SERVICE. WE MAKE NO GUARANTEES ABOUT THE ACCURACY OR RELIABILITY OF ANY CONTENT ON RECHAT, INCLUDING MEMBER PROFILES, ADVICE, STATEMENTS, OR OTHER INFORMATION. YOU BEAR SOLE RESPONSIBILITY FOR YOUR USE OF SUCH CONTENT.</p>

<p className="terms_content_p">UNDER NO CIRCUMSTANCES WILL RECHAT, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS AND THIRD PARTY PROVIDERS BE LIABLE FOR ANY DAMAGES ARISING FROM YOUR USE OF RECHAT. THIS INCLUDES DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT RECHAT IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

<p className="terms_content_p">SOME JURISDICTIONS DO NOT ALLOW LIABILITY LIMITATIONS, SO PORTIONS OF THESE LIMITATIONS MAY NOT APPLY TO YOU. IN ALL CASES, OUR MAXIMUM LIABILITY IS LIMITED TO $10.00 USD.</p>

<h4 className="terms_content_boldText">Indemnification</h4>
<p className="terms_content_p">You agree to indemnify and defend ReChat and its affiliated parties against all claims and costs arising from your breach of this Agreement. ReChat may assume exclusive defense of any matter subject to indemnification by you at its own expense.</p>

<h4 className="terms_content_boldText">Changes to the Agreement</h4>
<p className="terms_content_p">We may modify these terms at any time, with changes taking effect upon publication on ReChat. Your continued use of ReChat after changes constitutes acceptance of the modified terms.</p>
<p className="terms_content_p">We recommend reviewing this Agreement periodically for updates.</p>

<h4 className="terms_content_boldText">Miscellaneous</h4>
<p className="terms_content_p">This Agreement represents our complete understanding regarding ReChat usage and is governed by United States law. All disputes must be resolved through arbitration in the United States. Claims must be filed within one year of arising or be barred. If any provision is found unenforceable, it will be modified to the minimum extent necessary while preserving the Agreement's intent.</p>

<p className="terms_content_p">This Agreement cannot be assigned without ReChat's written consent. No agency, partnership, joint venture, or employment relationship is created. Headings are for convenience only. Official notices must be in writing with confirmed receipt.</p>

<h4 className="terms_content_boldText">Disclosure and Communication</h4>
<p className="terms_content_p">We may send you electronic communications about ReChat changes and services. We may collect and share anonymous statistical information about usage and demographics.</p>

                </div>
            </div>
            
        </div>
    )
}

export default Terms;
