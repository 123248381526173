import "./Features.css";
import React from "react";
import iPhone from "../../assets/images/iPhone.png";
import Browser from "../../assets/images/Browser_2.png";
import noSymbol from "../../assets/images/no-symbol.png";

function Features() {
  return (
    <div className="Features">
      <div className="Features_1">
        <div className="Features__whoTitle">Who Uses ReChat</div>
        <div className="Features__card_whoDescription">
          A wide variety of users in academia and corporations who want to
          gather information through conversations among real participants are
          encouraged to use ReChat. .
        </div>
        <div className="Features__card_whoDescription">
          If you have any publications using ReChat, please cite us in your
          publication and contact us to add your publication to our website.{" "}
        </div>

        <div className="Features__What_Middle_Title">What ReChat Can do</div>
      </div>

      <div className="Features_2">
        <div className="Homepage__steps Features_steps">
          <div className="Homepage__card right_align">
            <div className="Homepage__number">1</div>
            <div className="Homepage__card_title">Customize Features</div>
            <div className="Features_2_card1_description">
              <ul>
                <li>The number of participants in a chat.</li>
                <li>The duration of the chat (in minutes or by messages).</li>
                <li>What (if any) emoticons participants can use.</li>
                <li>
                  When and if the chat participants will receive a warning
                  message before the chat ends.
                </li>
                <li>
                  Whether chat participants can see timestamps on their
                  messages.
                </li>
              </ul>
            </div>
          </div>

          <div className="Homepage__card right_align">
            <div className="Features_2_card2number">2</div>
            <div className="Homepage__card_title">Match Participants</div>
            <div className="Features_2_card2_description">
              <ul>
                <li>
                  Create multiple chat rooms to match different types of
                  participants through conditional survey flow.
                </li>
                <li>
                  Create one chat room to match participants with the next
                  available participant.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <img className="hide_on_mobile" src={iPhone} alt="iPhone" />
        <div className="Homepage__steps Features_steps">
          <div className="Homepage__card">
            <div className="Features_2_card3number">3</div>
            <div className="Homepage__card_title">
              Interaction With Participants
            </div>
            <div className="Features_2_card3_description">
              <ul>
                <li>
                  As an administrator: Working from the ReChat interface,you can
                  chat with live participants with the name “admin.”
                </li>
                <li>
                  As a confederate: Working from a separate ReChat link, you can
                  chat with live participants with the name “other participant.”
                </li>
                <li>Use a bot to replace human experimenters.</li>
              </ul>
            </div>
          </div>

          <div className="Homepage__card">
            <div className="Homepage__number">4</div>
            <div className="Homepage__card_title">
              Unique Colour Code for Participants
            </div>
            <div className="Features_2_card4_description">
              Differentiate chatters with different font colours.
            </div>
          </div>

          <div className="Homepage__card">
            <div className="Homepage__number">5</div>
            <div className="Homepage__card_title">
              Interaction between Participants
            </div>
            <div className="Features_2_card5_description">
              Allow participants to interact with other participants while they
              view different instructions on the survey page.
            </div>
          </div>
        </div>
      </div>

      <div className="Features_3">
        <div className="Features_3__left">
          <img src={noSymbol} className="Features_3_nosymbol_png" alt="Star" />
          <div className="Features__featureTitle" style={{ marginTop: "15px" }}>
            What ReChat Cannot Do
          </div>
          <div className="Features_3_description">
            <ul>
              <li>
                Participants cannot return to a chat. Once they have left the
                page, they are disconnected.{" "}
              </li>
              <li>
                Participants cannot chat, move on to another page, and then get
                rematched with the same chat partner later
              </li>
              <li>Chat participant names cannot be customized. </li>
            </ul>
          </div>
        </div>
        <img
          src={Browser}
          alt="Browser"
          className="Features_3__right hide_on_mobile"
        />
      </div>
    </div>
  );
}

export default Features;
