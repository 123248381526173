import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about">
      <div className="about__bgbox1 " />
      <div className="about__bgbox2 " />
      <div className="about_wrapper">
        <div className="about_title">
          <h2>About Us</h2>
        </div>
        <div className="about_content">
          <p>
            <b>ReChat</b> is a self-service tool that enables you to design,
            manage, record, and analyze online chats among participants. With
            ReChat, you can easily create chat sessions, assign participants,
            embed your chat platform into online surveys, monitor participant
            interactions in real-time (either by you or through bots), and
            export chat data for further analysis.
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
